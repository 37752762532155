// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre.web";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreFaq from "../../blocks/helpcentre/src/HelpCentreFaq.web";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Location from "../../blocks/location/src/Location";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Reviews from "../../blocks/reviews/src/Reviews.web";
import AddReview from "../../blocks/reviews/src/AddReview";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Notifications from "../../blocks/notifications/src/Notifications";
import Maps from "../../blocks/maps/src/Maps";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import ICalendarSupport from "../../blocks/icalendarsupport/src/ICalendarSupport";
import Search from "../../blocks/search/src/Search";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Reservations from "../../blocks/reservations/src/Reservations";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Geofence from "../../blocks/geofence/src/Geofence";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import PricingEngine from "../../blocks/pricingengine/src/PricingEngine";
import Cfmatchingalgorithm1 from "../../blocks/cfmatchingalgorithm1/src/Cfmatchingalgorithm1";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import SideMenuBasic from "../../blocks/navigationmenu/src/SideMenu";
import SignUpDriver from "../../blocks/email-account-registration/src/SignUpDriver";
import SignUpDriverNext from "../../blocks/email-account-registration/src/SignUpDriverNext";
import SignUpDriverStep3 from "../../blocks/email-account-registration/src/SignUpDriverStep3";
import SignUpDriverStep4 from "../../blocks/email-account-registration/src/SignUpDriverStep4";

import SignUpRider from "../../blocks/email-account-registration/src/SignUpRider.web";
import SignUpRiderSuccess from "../../blocks/email-account-registration/src/SignUpRiderSuccess.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import DriverSucessScreen from "../../blocks/email-account-registration/src/DriverSucessScreen.web";
import DriverFailureScreen from "../../blocks/email-account-registration/src/DriverFailureScreen.web";
import PlanYourRide from "../../blocks/projecttemplates/src/planyourride/PlanYourRide.web"
import ProjectTemplates from '../../blocks/projecttemplates/src/ProjectTemplates.web'
import TwoFactorAuthentication from "../../blocks/rolesandpermissions2/src/TwoFactorAuthentication.web";
import  ChooseFromMap  from '../../blocks/projecttemplates/src/choosefrommap/ChooseFromMap.web';

const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreFaq,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Location:{
 component:Location,
path:"/Location"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Maps:{
 component:Maps,
path:"/Maps"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
ICalendarSupport:{
 component:ICalendarSupport,
path:"/ICalendarSupport"},
Search:{
 component:Search,
path:"/Search"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
PricingEngine:{
 component:PricingEngine,
path:"/PricingEngine"},
Cfmatchingalgorithm1:{
 component:Cfmatchingalgorithm1,
path:"/Cfmatchingalgorithm1"},
SignUpDriver:{
  component:SignUpDriver,
 path:"/SignUpDriver"},
 SideMenuBasic:{
  component:SideMenuBasic,
 path:"/SideMenu"},
 SignUpDriverNext:{
  component:SignUpDriverNext,
 path:"/SignUpDriverNext"},
 SignUpDriverStep3:{
  component:SignUpDriverStep3,
 path:"/SignUpDriverStep3"},
 SignUpDriverStep4:{
  component:SignUpDriverStep4,
 path:"/SignUpDriverStep4"},
 SignUpRider:{
  component:SignUpRider,
  path:"/SignUpRider"
 },
 SignUpRiderSuccess:{
  component:SignUpRiderSuccess,
  path:"/SignUpRiderSuccess"
 },
 ProjectTemplates:{
  component:ProjectTemplates,
  path:"/ProjectTemplates"
 },
  ResetPassword: {
    component: ResetPassword,
    path: "/ResetPassword"
  },
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  DriverSucessScreen: {
    component: DriverSucessScreen,
    path: '/DriverSucessScreen',
  },
  DriverFailureScreen: {
    component: DriverFailureScreen,
    path: '/DriverFailureScreen',
  },
  PlanYourRide: {
    component: PlanYourRide,
    path: '/PlanYourRide',
  },
  TwoFactorAuthentication: {
    component: TwoFactorAuthentication,
    path: '/TwoFactorAuthentication',
  },
  ChooseFromMap: {
    component: ChooseFromMap,
    path: '/ChooseFromMap',
  },
}

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;